<template>
  <div class="container space-1">
    <div class="rounded overflow-hidden space-top-2 space-top-lg-0 px-3 px-md-8 pr-lg-0 shadow" style="background-color:#EAF4FD">
      <div class="row justify-content-lg-between align-items-lg-center no-gutters">
        <div class="col-lg-6 space-lg-1 text-center text-lg-left">
          <h2 class="mb-3">What is API Marketplace?</h2>
          <p class="mb-4">API Marketplaces provide a central location where businesses can find APIs from a variety of sources, making it easy to find the right API for their needs. They also offer a variety of features such as ratings and reviews, so businesses can be sure they are getting a high-quality API. API Marketplaces are revolutionizing the digital business world by making APIs faster, better, and more accessible than ever before.</p>
          <a id="ga-OW02-05-03" href="https://blog.openapihub.com/en-us/what-is-api-marketplace/?_gl=1*gy1op1*_gcl_au*OTAyNDU1OTUxLjE2OTQ0MDAwNDA." class="btn btn-primary transition-3d-hover" target="_blank">Learn More about API Marketplace</a>
        </div>
        <div class="col-lg-6 space-top-1 ml-auto">
          <div class="w-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-community.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA'
}
</script>

<style lang="scss" scoped>
</style>
