<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">HOW IT WORKS</span>
        <h2>Publish Your API to the API Hub Community</h2>
        <p>Extend your API ecosystem to the API Hub Community in minutes.</p>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">Publish Your API Portal as Public Listed</h3>
          <p>To connect with the API Hub Community, you need to enable public access of your API portal so that the community can explore your API catalogue easily.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/community/community-feature-1.svg" alt="Publish Your API Portal as Public Listed">
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">Polish Your API Portal Guide and API Tutorials</h3>
          <p>The guide and API tutorials are the first interface to the community developer, and you need to polish them and keep them up to date. You can also leverage our CI/CD pipeline on this.</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/community/community-feature-2.svg" alt="Polish Your API Portal Guide and API Tutorials">
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">Grant API Access to the API Hub Community</h3>
          <p>Don't forget to grant the API collection access to the API Hub community so that our community members can subscribe your API collection via the self-served interface. It may be a good idea for you to define some free tier subscription plan to gain initial traction.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/community/community-feature-3.svg" alt="Grant API Access to the API Hub Community">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss" scoped>
</style>
